<template>
  <div class="add-space-container">
    <ds-header :title="title"></ds-header>
    <a-button type="link" class="back_btn" @click="$router.go(-1)">
        返回
      </a-button>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :colon="false"
      labelAlign="right"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 18 }"
    >
      <a-row>
        <a-col :span="span">
          <a-form-model-item label="当前项目：">
            <div>{{ currentProject }}</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="空间位置：">
            {{ spacePlace == '' ?  spatialPosition :  spacePlace }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="span">
          <a-form-model-item label="空间类型：" prop="spaceType" key="spaceType">
            <a-select
            :disabled="type == 6"
            @change="getSpaceType"
              v-model="form.spaceType"
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="item in filteredSpaceType" :value="item.id" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row >
        <a-col :span="span">
          <a-form-model-item label="空间名称：" prop="spaceName" key="spaceName">
            <a-input :disabled="type == 6" placeholder="请输入空间名称" v-model.trim="form.spaceName" :maxLength="50" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 2 && this.houseId.length > 0">
          <a-form-model-item label="房产证号：" prop="houseNumber" key="houseNumber">
            <a-select
            @change="selectHouse" 
            :disabled="disabled"
              v-model="form.houseNumber"
              placeholder="请选择房产证号"
              :defaultActiveFirstOption="defaultActiveFirstOption"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="item in houseId" :value="item" :key="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 2 && this.houseId.length == 0">
          <a-form-model-item label="房产证号：" prop="isHouseNumber">
            <div>——</div>
            <div v-if="false" style="color:#f5222d">请前往项目管理-项目详情中填写房产证号</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 3 || form.spaceType == 2">
          <a-form-model-item label="楼栋类型：" prop="buildingType" key="buildingType">
            <a-select
              v-model="form.buildingType"
              placeholder="请选择楼栋类型"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="item in buildingType" :value="item.id" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 4">
          <a-form-model-item label="房间类型：" prop="roomType" key="roomType">
            <a-select
              v-model="form.roomType"
              placeholder="请选择房间类型"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="item in houseType" :value="item.id" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="span" v-if="form.spaceType == 3 || form.spaceType == 2">
          <a-form-model-item label="建筑结构：" prop="structureType" key="structureType">
            <a-select
              @change="selectUpdate"
              v-model="form.structureType"
              placeholder="请选择建筑结构"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="item in structureType" :value="item.id" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 3 || form.spaceType == 2">
          <a-form-model-item label="层高：" prop="storeyHeight" key="storeyHeight">
            <a-input placeholder="请输入层高" @input="handleInput($event, 'storeyHeight',2)" addon-after="米" v-model.trim="form.storeyHeight"  />
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 3 || form.spaceType == 2">
          <a-form-model-item label="承重：" prop="bearing" key="bearing" >
            <a-input placeholder="请输入承重" @input="handleInput($event, 'bearing',3)" addon-after="KN/㎡" v-model.trim="form.bearing"  />
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 3 || form.spaceType == 2">
          <a-form-model-item label="建筑面积：" prop="buildArea" key="buildArea">
            <a-input  @input="handleInput($event, 'buildArea',2,1)" placeholder="请输入建筑面积" addon-after="m²" v-model.trim="form.buildArea" />
            <div class="hint-text" v-if="maintainableArea !== undefined">当前可维护面积：{{ maintainableArea }}㎡</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 6" >
          <a-form-model-item label="面积："  prop="land" >
            <a-form-model-item prop="start" :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
              <a-input  @input="handleInput($event, 'start',2,2)"  v-model="form.start" placeholder="请输入平方米" addon-after="m²" style="width: 100%" />
            </a-form-model-item>
            <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
              <a-icon type="swap" />
            </span>
            <a-form-model-item prop="end" :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
              <a-input  @input="handleInput($event, 'end',5,3)" v-model="form.end" placeholder="请输入亩" addon-after="亩" prop="end" style="width: 100%" />
            </a-form-model-item>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="form.spaceType == 5">
          <a-form-model-item label="面积：" >
            <a-input :maxLength="36" @input="handleInput($event, 'area',2)" placeholder="请输入面积" addon-after="m²" v-model.trim="form.area" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="maintainableArea !== undefined && form.spaceType == 4">
        <a-col :span="span">
            <div class="hint-text" style="margin-left:25%" v-if="maintainableArea !== undefined">当前可维护面积：{{ maintainableArea }}㎡</div>
        </a-col>
      </a-row>
       <a-row>
        <a-col :span="10" v-if="form.spaceType == 2">
          <a-form-model-item label="地址坐标" required >
            <a-form-model-item prop="longitude" :style="{ display: 'inline-block', width: 'calc(50% - 12px)' ,marginRight:'20px'}">
              <a-input  @input="handleInput($event, 'longitude',6)" v-model="form.longitude" placeholder="请输入经度"  style="width: 100%" />
            </a-form-model-item>
         
            <a-form-model-item prop="latitude" :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
              <a-input  @input="handleInput($event, 'latitude',6)" v-model="form.latitude" placeholder="请输入纬度"  prop="end" style="width: 100%" />
            </a-form-model-item>
          </a-form-model-item>
          
        </a-col>
      </a-row> 
      <a-row v-if="form.spaceType == 4">
        <a-col :span="span">


          <a-form-model-item label="套内面积：" prop="insideSpace" key="insideSpace">
            <a-input  @input="handleInput($event, 'insideSpace',2)" placeholder="请输入套内面积" addon-after="m²" v-model.trim="form.insideSpace" />
          </a-form-model-item>
        </a-col>
        <!-- <a-col :span="span">
          <a-form-model-item label="公摊面积：" prop="publicArea" key="publicArea">
            <a-input  @input="handleInput($event, 'publicArea',2)" placeholder="请输入公摊面积" addon-after="m²" v-model.trim="form.publicArea" />
          </a-form-model-item>
        </a-col> -->
      </a-row>
      <a-row v-if="form.spaceType == 3">
        <a-col :span="12">
          <a-form-model-item label="平面图：" prop="planImageUrl" ref="upload">
              <a-upload
              :action="IMG_API + '/oss/files'"
              list-type="picture-card"
              :file-list="fileList"
              :before-upload="beforeUpload"
              accept=".jpg,.png,.jpeg"
              :remove="handleRemove"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div v-if="fileList.length < 3">
                <a-icon type="plus" />
              </div>
            </a-upload>
            <div class="upload_tip" style="width:200px">请上传楼层平面图，最多三张</div>
            
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
            
            
            <!-- <div class="upload_tip">尺寸要求：1280 x 720px</div> -->
            <!-- <div class="upload_tip">格式：png，jpg，jpeg格式</div> -->
            
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <footer class="footer">
      <!-- <a-button @click="cancel" style="margin-right: 10px"> 返回 </a-button> -->
      <a-button type="primary" @click="submit"> 提交 </a-button>
    </footer>
    
  </div>
</template>
  
  <script>
import {
  labelCol,
  wrapperCol,
  rules,
} from '../utils/penetrateUtil/index';
import { mapState } from 'vuex';
import { IMG_API } from '@/config/index';
import * as api from '@/api/baseSet/spaceManage';
import { accAdd } from '../../../../utils/floatPointPrecision'; // 导入工具函数
export default {
  name: 'addSpace',
  computed: {
    title() {
      return `${this.$route.query.spaceId ? '编辑' : '添加'}空间`;
    },
    ...mapState({
      projectOptions: (state) => state.projectListForCommuterBus,
    }),
    filteredSpaceType() {
      if (this.type === null) return this.spaceType;
      return this.spaceType.filter(item => this.typeMap[this.type]?.includes(item.id));
    },
  },
  data() {
    return {
      type:null,
      inputError:'',
      defaultActiveFirstOption:false,
      IMG_API: IMG_API,
      labelCol,
      wrapperCol,
      span: 10,
      start:'',
      end:'',
      rules,
      disabled:false,//房产证是否可编辑
      currentProject:'',//当前项目
      spatialPosition:'',//空间位置
      land:'',
      form: {
        longitude:'',//经度
        latitude:'',//纬度
        start:"",
        end:"",
        spaceType: undefined,//空间类型
        deviceTypeFlag: undefined,
        providerCode: undefined,
        parentSpaceId:'',//父级空间id
        spaceName:'',//空间名称
        buildingType:undefined,//楼栋类型
        structureType:undefined,//建筑结构
        storeyHeight:'',//层高
        bearing:'',//承重
        buildArea:'',//建筑面积
        houseNumber:undefined ,//房屋编号（所属房屋
        planImageUrl:'',//背景图
        insideSpace:'',//套内面积
        publicArea:'',//公摊面积
        area:'',//面积
        roomType:undefined,//房间类型
        parentSpaceName:''
      },
      parentSpaceId:'',//详情父级空间id
      imgUrl:[],
      fileList:[],
      maintainableArea:undefined,//可维护面积
      houseId:[],
      topId:'',
      currentPosition:'',//详情id
      spaceId:'',
      projectList: [],
      id: this.$route.query.id,
      headers: { Authorization: JSON.parse(localStorage.getItem('user')).accessToken },
      previewVisible: false,
      previewImage: '',
      spaceType: [
        { id: 2, name: '楼栋' },
        { id: 3, name: '楼层' },
        { id: 4, name: '房间' },
        { id: 6, name: '土地' },
        { id: 5, name: '其他' },
      ], //楼栋类型
      typeMap: {
        1: [2, 6, 5],
        2: [3, 5],
        3: [4, 5],
        4: [5],
        5: [5],
      },
      buildingType:[
        { id: 1, name: '办公室' },
        { id: 2, name: '厂房' },
        { id: 3, name: '其他' }
      ],
      structureType:[
        { id: 1, name: '钢结构' },
        { id: 2, name: '钢筋混凝土结构' },
        { id: 3, name: '砖混结构' },
        { id: 4, name: '框架结构' },
        { id: 5, name: '轻钢结构' },
        { id: 7, name: '钢筋混凝土框架结构' },
        { id: 8, name: '钢框架结构' },
        { id: 6, name: '其他' },
      ],
      key:'',
      typeId:'',
      spacePlace:'',
      houseType:[
      { id: 1, name: '可租' },
        { id: 2, name: '自用' },
        { id: 3, name: '公益' },
        { id: 4, name: '其他' },
      ]
    };
  },
  created() {
    if (this.$route.query.id) {
      this.currentProject = this.$route.query.name//当前项目
      this.form.parentSpaceId  = this.parentSpaceId = this.$route.query.id//空间位置id
      this.spatialPosition = this.$route.query.space_position
      this.form.parentSpaceName = this.$route.query.parentSpaceName//空间位置名字
      this.topId = this.$route.query.top//顶级id
    } 
    if(this.$route.query.spaceId) {
      this.spaceId = this.$route.query.spaceId
      this.getInit()
    }
    this.key = this.$route.query.key
    if(this.key != '0-0'){
      this.typeId = this.$route.query.type
      this.initType()
    }else{
      this.type = 1
    }
  },
 
  methods: {
    async initType() {
      let res = await api.getSpaceDetailInfo({
        spaceId:this.typeId
      })
      if(res.code == 200){
        this.type = res.data.spaceType
      }
    },
    async getInit() {//查详情
      let res = await api.getSpaceDetailInfo({
        spaceId:this.spaceId
      })
      if(res.code == 200){
        this.form = res.data
        this.form.parentSpaceName = res.data.spaceName
        this.currentPosition = res.data.id
        this.parentSpaceId = res.data.parentSpaceId
        this.spacePlace = res.data.spacePlace
        if(this.form.planImageUrl){
          let urls = this.form.planImageUrl.split(',')
          this.fileList = urls.map((item,index) => ({
            uid: index+1,
            name: item.substring(item.lastIndexOf('/') + 1),
            status: 'done',
            url: item,
            response:{
              redirect_uri:item
            }
        }));          
        }
        if(this.form.spaceType == 2){
          this.getHouse()
        }
        if(this.form.spaceType == 2 || this.form.spaceType == 3 || this.form.spaceType == 4){
          this.getMaintenance()
        }
        
        if(this.form.spaceType == 6){
          this.$set(this.form, 'start', res.data.area);
          this.$set(this.form, 'end', parseFloat((this.form.start / 666.6666667).toFixed(5)));
        }
      }
    },
    
    getSpaceType(e) {
      this.$refs.ruleForm.clearValidate();
      this.maintainableArea = undefined
      this.form.buildingType = undefined//楼栋类型
      this.form.structureType = undefined//建筑结构
      if(e == 2){
        this.getHouse()
      }
      if(e == 3 ||  e == 4 || (e == 2 && this.houseId.length == 1)){
          this.getMaintenance()
      }
      
      this.$set(this.form,'start','')
      this.$set(this.form,'end','')
      this.$forceUpdate()
    },
    async getMaintenance() {//维护面积
      let id = this.form.spaceType == 2 ? this.topId :this.parentSpaceId
      let houNum = this.form.spaceType == 2 ? this.form.houseNumber : ''
      let res = await api.getCountArea({
        positionId:id,
        spaceType:this.form.spaceType,
        houseNumber:houNum,
        spaceId:this.currentPosition || ''
      })
      this.maintainableArea = res.data.maintainableArea
    },
    async getHouse() {//房产证
      let res = await api.getProperty({
        projectId:this.topId
      })
      res.data == null || res.data.length == 0 ? this.houseId = [] : this.houseId = res.data
      if(res.data.length == 1){
        this.defaultActiveFirstOption = this.disabled = true
        this.form.houseNumber = res.data[0]
      this.getMaintenance()

      }else{
        this.defaultActiveFirstOption = this.disabled = false
      }
    },
    selectHouse(e) {
      this.form.houseNumber = e
      this.getMaintenance()
    },
    handleInput(event, inputName,digit,num) {//非汉字、符号，保留两位小数，不补0
      if(num == 1){
        this.inputError = ''
      }else  if(num == 2){
        if(this.form.start == ''){
          setTimeout(()=>{
            this.form.end = ''
          },1000)
        }else{
          this.form.end = ''
          setTimeout(()=>{
            this.form.land = 1
            this.form.end = parseFloat((Number(this.form.start) / 666.6666667).toFixed(5))
            this.$refs.ruleForm.clearValidate('end');
            this.$refs.ruleForm.clearValidate('land');
          },1000)
        }
        
      }else if(num == 3){
        if(this.form.end == ''){
          setTimeout(()=>{
            this.form.start = ''
          },1000)
        }else{
          this.form.start = ''
          setTimeout(()=>{
            this.form.land = 1
            this.form.start = parseFloat((Number(this.form.end ) * 666.6666667).toFixed(2))
            this.$refs.ruleForm.clearValidate('start');
            this.$refs.ruleForm.clearValidate('land');
          },1000)

        }
      }
      let value = event.target.value;
      
      // 只允许数字和一个小数点
      if (/^\d*\.?\d*$/.test(value)) {
        // 防止前导零
        if (value.startsWith('00')) {
          value = value.replace(/^0+/, '0');
        }
        // 如果已经输入10位数字，只允许第11位输入小数点
        if (value.length === 11 && value[10] !== '.') {
          value = value.substring(0, 10);
        }
        // 限制小数点后两位
        if (value.includes('.')) {
          let [integerPart, decimalPart] = value.split('.');
          if (decimalPart.length > digit) {
            decimalPart = decimalPart.substring(0, digit);
          }
          value = `${integerPart}.${decimalPart}`;
        }
        this.$set(this.form, inputName, value);
      } else {
        // 防止前导零
        if (value.startsWith('00')) {
          value = value.replace(/^0+/, '0');
        }
        // 如果已经输入10位数字，只允许第11位输入小数点
        if (value.length === 11 && value[10] !== '.') {
          value = value.substring(0, 10);
        }
        // 如果输入的不是合法的数字格式，则删除非法字符
        value = value.replace(/[^0-9.]/g, '');
        // 确保只有一个小数点
        let parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join(''); 
        }
        this.$set(this.form, inputName, value);
      }
    },
    handleRemove(file) {
      this.fileList = this.fileList.filter(item => item.uid !== file.uid);
      this.form.planImageUrl = ''
      if(this.fileList.length == 0){
        this.form.planImageUrl = ''
      }else{
        this.form.planImageUrl = this.fileList.map(file => file.response.redirect_uri).join(',');
      }
    },
    selectUpdate() {
      this.$forceUpdate();
    },
    houseNum() {//校验建筑面积不能大于维护面积
      // 清空错误信息
      // 检查输入是否为负数
      this.$message.config({
          maxCount:1
        })
      // if (this.form.buildArea < 0) {
      //   this.$message.error('建筑面积不能为负数');
      //   return false;
      // }
        // 检查输入是否大于 证载面积
      if (this.form.buildArea > this.maintainableArea) {
        this.$message.error('建筑面积不可以大于可维护面积');
        return false;
      }
      return true
    },
    // 提交
    async submit() {
      if(this.type == 6){
        this.$message.config({
          maxCount:1
        })
        this.$message.error('土地下面不可以创建空间');
        return false;
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if(this.form.spaceType == 2 || this.form.spaceType == 3){
            if(!this.houseNum()){
              return false
            }
            if(this.form.spaceType == 2 && this.form.latitude >90){
              this.$message.error('请输入有效经纬度值');
                return false
              }
            if(this.form.spaceType == 2 && this.form.longitude > 180){
              this.$message.error('请输入有效经纬度值');
              return false
            }
          }
          if(this.form.spaceType != 3){
            this.form.planImageUrl = ''
          }else{
            this.form.area = this.start
          }
          if(this.form.spaceType == 6){
            this.$refs.ruleForm.clearValidate('land');
            this.form.area = parseFloat(Number(this.form.start).toFixed(2))
            delete this.form.start
            delete this.form.end
          }
          if(this.form.spaceType == 4){
            let addNum = accAdd(this.form.insideSpace,0)
            if(addNum >  this.maintainableArea){
              this.$message.error('套内面积不能大于可维护面积');
              return
            }
          }
          if(this.spaceId){
            
            api.updateSpace(this.form).then(res=>{
              if(res.code ==200){
                localStorage.setItem('top',this.topId)

                this.$message.success('编辑成功');
                this.$router.back()
              }else{
                this.$message.error(res.msg);
              }
            })
          }else{
            api.createSpace(this.form).then(res=>{
              if(res.code ==200){
                localStorage.setItem('top',this.topId)

                this.$message.success('创建成功');
                this.$router.back()
              }else{
                this.$message.error(res.msg);
              }
            })
          }
         
        }
      });
    },
    //上传文件之前校验图片大小
    beforeUpload(file) {
      return new Promise(async (resolve, reject) => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isJPG) {
          this.$message.error('上传图片只能是jpg、png、jpeg格式!');
          return reject(false);
        }
        
        // const isSize = await this.checkImageWH(file, 1280, 720);
        // if (!isSize) {
        //   this.$message.error("上传尺寸为1280x720的图片!");
        //   return reject(false);
        // }
        return resolve(isJPG);
      });
    },
   
    handleChange({file,fileList}) {
      this.fileList = fileList;
      if(file.status == 'done'){
        this.form.planImageUrl = this.fileList.map(file => file.response.redirect_uri).join(',');
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      this.previewImage = file.url || file.response.redirect_uri;
      this.previewVisible = true;
    },
    // 取消
    cancel() {
      localStorage.setItem('top',this.topId)
      this.$router.replace('/baseSet/spaceManage')
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.add-space-container {
  position: relative;
  .back_btn{
    position: absolute;
    right: 5px;
    top: 20px;
  }
  .content {
    color: #000;
    font-weight: bold;
    line-height: 20px;
    margin-top: 10px;
    overflow-wrap: anywhere;
  }

  .footer {
    margin-top: 30px;
    margin-left: 100px;
  }

  .hint-text {
    height: 20px;
    line-height: 20px;
    color: #8e8e8e;
    font-size: 12px;
    margin-top: 10px;
  }

  .hint-text p:nth-of-type(2) {
    margin-top: -13px;
  }

  .device-text {
    color: #8e8e8e;
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;

    // margin-bottom: -30px;
    p {
      margin-top: -8px;
    }
  }
}
.aaa{
  color:red
}
</style>
  